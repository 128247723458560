export const offerList = {
    pl: [
        {
            offerName: 'Spółki kapitałowe',
        },
        {
            offerName: 'Spółki osobowe',
        },
        {
            offerName: 'Jednoosobowe działalności gospodarcze',
        },
        {
            offerName: 'Fundacje, stowarzyszenia',
        },
        {
            offerName: 'Spółdzielnie',
        },
        {
            offerName: 'Najmy prywatne',
        },
        {
            offerName: 'Rozliczanie dochodów z zagranicy',
        }
    ],
    en: [
        {
            offerName: 'Capital companies',
        },
        {
            offerName: 'Individual entrepreneurs',
        },
        {
            offerName: 'Sole proprietorships',
        },
        {
            offerName: 'Foundations, associations',
        },
        {
            offerName: 'Cooperatives',
        },
        {
            offerName: 'Private rentals',
        },
        {
            offerName: 'Foreign income settlements',
        }
    ]
}
