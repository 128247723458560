import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './Modal.scss';

const Modal = ({
    isOpen,
    onClose,
    children,
    width = '500px',
    height = 'auto',
    title,
    showCloseButton = true,
}) => {
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setIsAnimating(true);
        }
    }, [isOpen]);

    const handleClose = () => {
        setIsAnimating(false);
        setTimeout(onClose, 300); // Wait for animation to complete
    };

    const handleBackdropClick = (e) => {
        if (e.target === e.currentTarget) {
            handleClose();
        }
    };

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div
            className={`modal-backdrop ${isAnimating ? 'show' : ''}`}
            onClick={handleBackdropClick}
        >
            <div
                className={`modal-content ${isAnimating ? 'show' : ''}`}
                style={{ width, height }}
            >
                {showCloseButton && (
                    <button className="modal-close" onClick={handleClose}>
                        ×
                    </button>
                )}
                {title && <h2 className="modal-title">{title}</h2>}
                <div className="modal-body">
                    {children}
                </div>
            </div>
        </div>,
        document.body
    );
};

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
    title: PropTypes.string,
    showCloseButton: PropTypes.bool,
};

export default Modal;
