import React, { useEffect, useState } from 'react';
import './OfferSection.scss';
import { offerList } from '../../helpers/offer-list';

const OfferSection = ({ intl }) => {

    return (
        <section className="offer-section" id="offer-section">
            <div className="content-wrapper content-wrapper__section-offer">
                <h2 className="title">{intl.formatMessage({ id: 'homePage.offerSection.title' })}</h2>
                <p className="description">{intl.formatMessage({ id: 'homePage.offerSection.description' })}</p>
                <ul className={`offer-list lang-${intl.locale}`}>
                    <li className="offer-list__button">
                        <button>
                            {offerList[intl.locale][0]?.offerName}
                        </button>
                    </li>
                    <li className="offer-list__button">
                        <button>
                            {offerList[intl.locale][1]?.offerName}
                        </button>
                    </li>
                    <li className="offer-list__button">
                        <button>
                            {offerList[intl.locale][2]?.offerName}
                        </button>
                    </li>
                    <li className="offer-list__button">
                        <button>
                            {offerList[intl.locale][3]?.offerName}
                        </button>
                    </li>
                    <li className="offer-list__button">
                        <button>
                            {offerList[intl.locale][4]?.offerName}
                        </button>
                    </li>
                    <li className="offer-list__button">
                        <button>
                            {offerList[intl.locale][5]?.offerName}
                        </button>
                    </li>
                    <li className="offer-list__button">
                        <button>
                            {offerList[intl.locale][6]?.offerName}
                        </button>
                    </li>
                </ul>
            </div>
        </section>
    )
}

export default OfferSection;
