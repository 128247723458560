import React, { useState } from 'react';
import { Link } from 'react-scroll'
import './BenefitSection.scss';
import Modal from '../Layouts/Modal';
import List from '../Layouts/List';

import CardImage1 from '../../images/icons/accounting.svg'
import CardImage2 from '../../images/icons/hand-gesture.svg'
import CardImage3 from '../../images/icons/security.svg'
import CardImage4 from '../../images/icons/best-price.svg'
import { handlerTracking } from '../../helpers/ga';

const BenefitSection = ({ intl }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <section className="benefit-section" id="benefit-section">
            <div className="content-wrapper content-wrapper__section-benefit">
                <h2 className="title">{intl.formatMessage({ id: 'homePage.benefitSection.title' })}</h2>
                <div className="cards">
                    <div className="card shorter">
                        <img src={CardImage1} alt="accountant" />
                        <h3>{intl.formatMessage({ id: 'homePage.benefitSection.cards.card1.title' })}</h3>
                        <p>{intl.formatMessage({ id: 'homePage.benefitSection.cards.card1.description' })}</p>
                    </div>
                    <div className="card shorter">
                        <img src={CardImage2} alt="complexity" />
                        <h3>{intl.formatMessage({ id: 'homePage.benefitSection.cards.card2.title' })}</h3>
                        <p>
                            {intl.formatMessage({ id: 'homePage.benefitSection.cards.card2.description' })}
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={() => setIsModalOpen(true)}
                            >
                                <u>
                                    {intl.formatMessage({ id: 'homePage.benefitSection.cards.card2.span2' })}
                                </u>
                            </span>
                        </p>
                    </div>
                    <div className="card">
                        <img src={CardImage3} alt="security" />
                        <h3>{intl.formatMessage({ id: 'homePage.benefitSection.cards.card3.title' })}</h3>
                        <p>{intl.formatMessage({ id: 'homePage.benefitSection.cards.card3.description' })}</p>
                    </div>
                    <div className="card">
                        <img src={CardImage4} alt="stable-price" />
                        <h3>{intl.formatMessage({ id: 'homePage.benefitSection.cards.card4.title' })}</h3>
                        <p>
                            {intl.formatMessage({ id: 'homePage.benefitSection.cards.card4.description' })}
                            <span>
                                <Link to="contact-section" offset={-50} smooth={true} duration={800} type="button"
                                    onClick={() => handlerTracking('Sekcja Korzyści', 'Kliknięcie', 'Przejdź do formularza')}>
                                    {intl.formatMessage({ id: 'homePage.benefitSection.cards.card4.span1' })}
                                    {' '}
                                    <u>{intl.formatMessage({ id: 'homePage.benefitSection.cards.card4.span2' })}</u>
                                </Link>
                            </span>
                        </p>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title="Komunikacja pomiędzy Klientem a biurem"
                width="900px"
            >
                <List />
            </Modal>
        </section>
    );
}

export default BenefitSection;
