import React from 'react';
import './List.scss';

const List = () => {
    return (
        <div className="list-container">
            <h3 className="list-subtitle">3 sposoby przekazywania dokumentów:</h3>

            <div className="list-section">
                <h4 className="list-header">Przez aplikację SALDEO SMART – zgodnie z instrukcją</h4>
                <h5 className="list-subheader">Jak to działa?</h5>

                <ol className="list-items">
                    <li>
                        Klient wystawia faktury sprzedaży w udostępnionej przez biuro rachunkowe aplikacji saldeo smart,
                        <ul className="list-bullets">
                            <li>jest możliwość bezpośredniej wysyłki wystawionej faktury do kontrahenta, bezpośrednio z aplikacji "jednym przyciskiem"</li>
                            <li>kontrahent ma możliwość szybkiej płatności on line</li>
                            <li>faktura jest od razu widoczna dla biura rachunkowego, więc nie trzeba jej dodatkowo przekazywać</li>
                        </ul>
                    </li>
                    <li>
                        Klient przekazuje dokumenty (faktury zakupu, wyciągi bankowe, miesięczne raporty kasy fiskalnej, noty, pisma, etc.) przez aplikację saldeo smart zgodnie z instrukcją przekazaną przez biuro rachunkowe (polega to na zapisywaniu w prosty sposób dokumentów w PDF bezpośrednio w aplikacji),
                        <ul className="list-bullets">
                            <li>tworzenie elektronicznego archiwum dokumentów, dostępnego 24/h</li>
                            <li>możliwość łatwego sprawdzenia, które dokumenty już zostały przekazane, a które jeszcze nie</li>
                            <li>możliwość zastosowania filtrów, co umożliwia szybkie odnalezienie dokumentu</li>
                            <li>możliwość odznaczenia zapłaty przy dokumencie</li>
                        </ul>
                    </li>
                    <li>
                        Klient ma możliwość tworzenia raportów kasowych bezpośrednio przez aplikację,
                        <ul className="list-bullets">
                            <li>ułatwia prowadzenie kasy</li>
                            <li>raport kasowy wraz z dowodami kasowymi jest od razu widoczny dla biura rachunkowego, więc nie trzeba go dodatkowo przekazywać</li>
                        </ul>
                    </li>
                    <li>Po przekazaniu wszystkich dokumentów dotyczących danego miesiąca Klient zaznacza kwadracik "komplet dokumentów", co oznacza, że księgowa może już rozliczyć i zamknąć miesiąc</li>
                    <li>W razie stwierdzenia przez księgową braków lub błędów w dokumentacji, kontaktuje się ona z Klientem drogą e-mailową informując o brakach i / lub błędach w przekazanej dokumentacji</li>
                    <li>
                        Po rozliczeniu miesiąca księgowa zapisuje w aplikacji kwoty zobowiązań podatkowych, załącza wysłane deklaracje i raporty (bilans, RZiS, zestawienie obrotów i sald, rozrachunki, etc.). Klient otrzymuje również e-maila z informacją o kwotach zobowiązań podatkowych,
                        <ul className="list-bullets">
                            <li>do kwot zobowiązań podatkowych, wydruków deklaracji, raportów Klient ma dostęp 24/h</li>
                        </ul>
                    </li>
                </ol>
            </div>

            <div className="list-section">
                <h4 className="list-header">na dedykowany adres e-mail</h4>
                <h5 className="list-subheader">Jak to działa?</h5>
                <ol className="list-items">
                    <li>Klient przekazuje wszystkie dokumenty na dedykowany adres e-mail</li>
                    <li>Pracownik biura rachunkowego zapisuje wszystkie przekazane dokumenty w aplikacji saldeo smart, dzięki czemu Klient ma w nie wgląd 24h na dobę (tworzy się tzw. Elektroniczne archiwum dokumentów)!</li>
                    <li>W razie stwierdzenia przez księgową braków lub błędów w dokumentacji, kontaktuje się ona z Klientem drogą e-mailową informując o brakach i / lub błędach w przekazanej dokumentacji</li>
                    <li>Po rozliczeniu miesiąca księgowa informuje e-mailem o kwotach zobowiązań podatkowych, załącza wysłane deklaracje i raporty (bilans, RZiS, zestawienie obrotów i sald, rozrachunki, etc.)</li>
                    <li>Klient poprzez aplikację saldeo smart może wystawiać faktury sprzedaży i wysłać je bezpośrednio z aplikacji do kontrahenta oraz ma możliwość tworzenia raportów kasowych wraz z dokumentami kasowymi (KP, KW). Dokumenty wygenerowane przez aplikację (faktury sprzedaży, raporty kasowe) są widoczne dla biura rachunkowego i nie wymagają ich przekazywania.</li>
                </ol>
            </div>

            <div className="list-section">
                <h4 className="list-header">dokumenty papierowe do siedziby biura</h4>
                <h5 className="list-subheader">Jak to działa?</h5>
                <ol className="list-items">
                    <li>Klient przekazuje wszystkie dokumenty do siedziby biura rachunkowego na Afrykańską</li>
                    <li>Pracownik biura rachunkowego skanuje wszystkie przekazane dokumenty i zapisuje je w aplikacji saldeo smart, dzięki czemu Klient ma w nie wgląd 24h na dobę!</li>
                    <li>Przekazane oryginały dokumentów są Klientowi zwracane przy kolejnej wizycie</li>
                    <li>W razie stwierdzenia przez księgową braków lub błędów w dokumentacji, kontaktuje się ona z Klientem drogą e-mailową informując o brakach i / lub błędach w przekazanej dokumentacji</li>
                    <li>Po rozliczeniu miesiąca księgowa informuje e-mailem o kwotach zobowiązań podatkowych, załącza wysłane deklaracje i raporty (bilans, RZiS, zestawienie obrotów i sald, rozrachunki, etc.)</li>
                    <li>Klient poprzez aplikację saldeo smart może wystawiać faktury sprzedaży i wysłać je bezpośrednio z aplikacji do kontrahenta oraz ma możliwość tworzenia raportów kasowych wraz z dokumentami kasowymi (KP, KW). Dokumenty wygenerowane przez aplikację (faktury sprzedaży, raporty kasowe) są widoczne dla biura rachunkowego i nie wymagają ich przekazywania.</li>
                </ol>
            </div>

            <div className="list-warning">
                UWAGA! Wybierając przekazywanie dokumentów przez aplikację saldeo smart zgodnie z instrukcją przewidziany jest rabat!!!
            </div>
        </div>
    );
};

export default List;
