import React, { useEffect, useRef, useState } from 'react';
import './ContactSection.scss';

import OfferTopLeftImg from './../../images/offer-top-left-icon.svg'
import OfferTopRightImg from './../../images/offer-top-right-icon.svg'


import { handlerTracking } from '../../helpers/ga';

const initialFormData = {
    vatNumber: '',
    name: '',
    email: '',
    phone: '',
    legalBusinessForm: '',
    monthlyCreatedInvoices: '',
    businessFormDescription: '',
    monthlyReceivedInvoices: '',
    cashRegister: '',
    monthlyTransactionQtyAbroadClientServices: '',
    monthlyTransactionQtyAbroadClientFreight: '',
    uopEmployees: '',
    quantityMandatory: '',
    internetSalesToAbroadClient: '',
    specialQtyBankAccounts: '',
    specialQtyInternationalBankAccount: '',
    specialCashRegister: '',
    specialInternationalCashRegister: '',
    specialCardOrBlikPayment: '',
    agreement1: false,
    agreement2: false,
    otherInformation: '',
}

const ContactSection = ({ intl }) => {
    const isMounted = useRef(false);
    const [showAdminAgreement, setShowAdminAgreement] = useState(false);
    const [seenAdminAgreement, setSeenAdminAgreement] = useState(false);
    const [formData, setFormData] = useState(initialFormData);

    const [errorMessages, setErrorMessages] = useState({})
    const [errorMessage, setErrorMessage] = useState('')
    const [sending, setSending] = useState(false);
    const [alreadySubmitted, setAlreadySubmitted] = useState(false);

    const [isSpecial, setIsSpecial] = useState(false);

    useEffect(() => {
        if (
            formData?.legalBusinessForm !== '' &&
            formData?.legalBusinessForm !== 'none' &&
            formData?.legalBusinessForm !== 'jdg' &&
            formData?.legalBusinessForm !== 'spc' &&
            formData?.legalBusinessForm !== 'spj' &&
            formData?.legalBusinessForm !== 'spp'
        ) {
            setIsSpecial(true)
        } else {
            setIsSpecial(false)
        }
    }, [formData?.legalBusinessForm])

    const handlerSendMail = () => {
        let endpoint = '';

        if (areAnyErrors || alreadySubmitted) return;

        if (window.location.href.includes('localhost')) {
            endpoint = 'http://localhost:3000/api/email'
        } else {
            endpoint = 'https://erachunkowa-mailer.vercel.app/api/email'
        }

        setSending(true);
        fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...formData }),
        }).then(async (response) => {
            const data = await response.json();

            if (data.success) {
                setFormData(initialFormData);
                setAlreadySubmitted(true);
            }

            if (Object.keys(data.errorMessages)?.length > 0) {
                setErrorMessages(data.errorMessages)
                setErrorMessage(data.errorMessage)
            }

        }).catch((error) => {
            console.error('Error cough:', error);
        }).finally(() => {
            setSending(false);
        })
    };

    const submitFormData = (e) => {
        e.preventDefault()
        handlerTracking('Sekcja Ankieta', 'Kliknięcie', 'Wysłano zgłoszenie')


        if (sending) return;
        handlerSendMail();
    }

    useEffect(() => {
        if (seenAdminAgreement) return;

        if (isMounted.current) {
            handlerShowAdminAgreement()
            return;
        }

        isMounted.current = true;
    }, [formData])

    const handlerCloseAdminAgreements = () => {
        setShowAdminAgreement(false)
        setSeenAdminAgreement(true);
    }


    const handlerShowAdminAgreement = () => {
        if (showAdminAgreement) return;
        setShowAdminAgreement(!showAdminAgreement)
    }

    const areAnyErrors = Object.values(errorMessages)?.filter(e => !!e)?.length > 0;

    return (
        <section className="contact-section" id="contact-section">
            <div className="contact-section__image contact-section__image--left">
                <img src={OfferTopLeftImg} alt="offer-top-left" />
            </div>
            <div className="contact-section__image contact-section__image--right">
                <img src={OfferTopRightImg} alt="offer-top-right" />
            </div>

            {showAdminAgreement && (
                <div className="policy-wrapper">
                    <div className="policy-wrapper__content">
                        <p>
                            Administratorem państwa danych osobowych jest eRachunkowa Sp. z o. o. z siedzibą w Warszawie
                            (03-966) przy
                            ul. Afrykańska 12D lok. 11. Wszystkie pytania i wątpliwości prosimy kierować do naszego
                            Inspektora Ochrony Danych
                            na adres admin@erachunkowa.pl
                            <br /><br />
                            Państwa dane przetwarzane są w celu odpowiedzi na wiadomość wysłaną za pomocą formularza
                            kontaktowego,
                            podjęcia na Państwa wniosek określonych dziłań przed zawarciem umowy lub wysyłaniem Państwu
                            treści
                            marketingowych. Przysługuje Państwu prawo dostępu do danych, ich sprostowania, usunięcia,
                            ograniczenia
                            przetwarzania, wniesienia sprzeciwu i cofnięcia w każym momencie udzielonej zgody bez wpływu
                            na zgodność z
                            prawem przetwarzania danych, którego dokonano na podstawie zgody przed jej wycofaniem. Masz
                            również prawo
                            do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych.
                            <br /><br />
                            Informujemy, że Państwa zgoda może zostać cofnięta w dowolnym momencie przez wysłanie
                            wiadomości e-mail na
                            adres naszego Insektora Ochrony Danych admin@erachunkowa.pl spod adresu, którego zgoda
                            dotyczy.
                        </p>
                        <button className="policy-wrapper__close-button" onClick={handlerCloseAdminAgreements}>X
                        </button>
                    </div>
                </div>
            )}
            <div className="content-wrapper content-wrapper__section-contact">
                <h2 className="title">{intl.formatMessage({ id: 'homePage.contactSection.title' })}</h2>
                <p className="description">{intl.formatMessage({ id: 'homePage.contactSection.description' })}</p>
                <div className="form">
                    <form className="form" onSubmit={submitFormData}>
                        <div className="form-table">
                            <div className="form-table__row">
                                <div className="form-table__cell form-table__cell--label">
                                    <p className="input-wrapper__label">NIP firmy*</p>
                                </div>
                                <div className="form-table__cell form-table__cell--input">
                                    <input
                                        type="text"
                                        name="vatNumber"
                                        id="vatNumber"
                                        placeholder="1234567891"
                                        className="input-wrapper__input"
                                        value={formData.vatNumber} onChange={(e) => {
                                            setFormData({ ...formData, vatNumber: e.target.value })
                                            setErrorMessages({ ...errorMessages, vatNumber: '' })
                                        }}
                                    />
                                    {errorMessages.vatNumber && <small className="input-wrapper__error-message">{errorMessages.vatNumber}</small>}
                                </div>
                            </div>

                            <div className="form-table__row">
                                <div className="form-table__cell form-table__cell--label">
                                    <p className="input-wrapper__label">Osoba do kontaktu (Imię i Nazwisko)*</p>
                                </div>
                                <div className="form-table__cell form-table__cell--input">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder='Jan Kowalski'
                                        className="input-wrapper__input"
                                        value={formData.name} onChange={(e) => {
                                            setFormData({ ...formData, name: e.target.value })
                                            setErrorMessages({ ...errorMessages, name: '' })
                                        }}
                                    />
                                    {errorMessages.name && <small className="input-wrapper__error-message">{errorMessages.name}</small>}
                                </div>
                            </div>

                            <div className="form-table__row">
                                <div className="form-table__cell form-table__cell--label">
                                    <p className="input-wrapper__label">E-mail*</p>
                                </div>
                                <div className="form-table__cell form-table__cell--input">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="jan.kowalski@erachunkowa.pl"
                                        className="input-wrapper__input"
                                        value={formData.email} onChange={(e) => {
                                            setFormData({ ...formData, email: e.target.value })
                                            setErrorMessages({ ...errorMessages, email: '' })
                                        }}
                                    />
                                    {errorMessages.email && <small className="input-wrapper__error-message">{errorMessages.email}</small>}
                                </div>
                            </div>

                            <div className="form-table__row">
                                <div className="form-table__cell form-table__cell--label">
                                    <p className="input-wrapper__label">Numer Telefonu*</p>
                                </div>
                                <div className="form-table__cell form-table__cell--input">
                                    <input
                                        type="phone"
                                        name="phone"
                                        id="phone"
                                        placeholder="555444222"
                                        className="input-wrapper__input"
                                        value={formData.phone} onChange={(e) => {
                                            setFormData({ ...formData, phone: e.target.value })
                                            setErrorMessages({ ...errorMessages, phone: '' })
                                        }}
                                    />
                                    {errorMessages.phone && <small className="input-wrapper__error-message">{errorMessages.phone}</small>}
                                </div>
                            </div>

                            <div className="form-table__row">
                                <div className="form-table__cell form-table__cell--label">
                                    <p className="input-wrapper__label">Forma prawna prowadzonej działalności*</p>
                                </div>
                                <div className="form-table__cell form-table__cell--input">
                                    <select
                                        type="legalBusinessForm"
                                        name="legalBusinessForm"
                                        id="legalBusinessForm"
                                        className="input-wrapper__input"
                                        value={formData.legalBusinessForm} onChange={(e) => {
                                            setFormData({ ...formData, legalBusinessForm: e.target.value })
                                            setErrorMessages({ ...errorMessages, legalBusinessForm: '' })
                                        }}
                                    >
                                        <option value="none">Wybierz formę</option>
                                        <option value="spz">Spółka z o.o.</option>
                                        <option value="jdg">Jednoosobowa działalność gospodarcza</option>
                                        <option value="spc">Spółka cywilna</option>
                                        <option value="spj">Spółka jawna</option>
                                        <option value="spk">Spółka komandytowa</option>
                                        <option value="spp">Spółka partnerska</option>
                                        <option value="spka">Spółka komandytowo-akcyjna</option>
                                        <option value="psa">Prosta spółka akcyjna</option>
                                        <option value="spa">Spółka akcyjna</option>
                                        <option value="fund">Fundacja / stowarzyszenie</option>
                                        <option value="spol">Spółdzielnia</option>
                                    </select>
                                    {errorMessages.legalBusinessForm && <small className="input-wrapper__error-message">{errorMessages.legalBusinessForm}</small>}
                                </div>
                            </div>

                            <div className="form-table__row">
                                <div className="form-table__cell form-table__cell--label">
                                    <p className="input-wrapper__label">Średniomiesięczna ilość wystawionych faktur* <span style={{ color: '#ffd2d2' }}>(lub przewidywana ilość)</span></p>
                                </div>
                                <div className="form-table__cell form-table__cell--input">
                                    <input
                                        type="monthlyCreatedInvoices"
                                        name="monthlyCreatedInvoices"
                                        id="monthlyCreatedInvoices"
                                        placeholder="Oreintacyjna liczba"
                                        className="input-wrapper__input"
                                        value={formData.monthlyCreatedInvoices} onChange={(e) => {
                                            setFormData({ ...formData, monthlyCreatedInvoices: e.target.value })
                                            setErrorMessages({ ...errorMessages, monthlyCreatedInvoices: '' })
                                        }}
                                    />
                                    {errorMessages.monthlyCreatedInvoices && <small className="input-wrapper__error-message">{errorMessages.monthlyCreatedInvoices}</small>}
                                </div>
                            </div>

                            <div className="form-table__row">
                                <div className="form-table__cell form-table__cell--label">
                                    <p className="input-wrapper__label">Przedmiot działalności <span style={{ color: '#ffd2d2' }}>(Krótki opis, czym zajmuje się firma, np. sklep internetowy)</span></p>
                                </div>
                                <div className="form-table__cell form-table__cell--input">
                                    <textarea
                                        type="businessFormDescription"
                                        name="businessFormDescription"
                                        id="businessFormDescription"
                                        rows={4}
                                        placeholder="Opis..."
                                        className="input-wrapper__input"
                                        value={formData.businessFormDescription} onChange={(e) => {
                                            setFormData({ ...formData, businessFormDescription: e.target.value })
                                            setErrorMessages({ ...errorMessages, businessFormDescription: '' })
                                        }}
                                    />
                                    {errorMessages.businessFormDescription && <small className="input-wrapper__error-message">{errorMessages.businessFormDescription}</small>}
                                </div>
                            </div>

                            <div className="form-table__row">
                                <div className="form-table__cell form-table__cell--label">
                                    <p className="input-wrapper__label">Średniomiesięczna ilość faktur zakupu* <span style={{ color: '#ffd2d2' }}>(lub przewidywana ilość)</span></p>
                                </div>
                                <div className="form-table__cell form-table__cell--input">
                                    <input
                                        type="monthlyReceivedInvoices"
                                        name="monthlyReceivedInvoices"
                                        id="monthlyReceivedInvoices"
                                        placeholder="Oreintacyjna liczba"
                                        className="input-wrapper__input"
                                        value={formData.monthlyReceivedInvoices} onChange={(e) => {
                                            setFormData({ ...formData, monthlyReceivedInvoices: e.target.value })
                                            setErrorMessages({ ...errorMessages, monthlyReceivedInvoices: '' })
                                        }}
                                    />
                                    {errorMessages.monthlyReceivedInvoices && <small className="input-wrapper__error-message">{errorMessages.monthlyReceivedInvoices}</small>}
                                </div>
                            </div>

                            <div className="form-table__row">
                                <div className="form-table__cell form-table__cell--label">
                                    <p className="input-wrapper__label">Kasa fiskalna</p>
                                </div>
                                <div className="form-table__cell form-table__cell--input">
                                    <div
                                        className={`input-wrapper__switch ${formData.cashRegister ? 'active' : ''}`}
                                    >
                                        <div
                                            onClick={(e) => {
                                                setFormData({ ...formData, cashRegister: false })
                                                setErrorMessages({ ...errorMessages, cashRegister: '' })
                                            }}
                                        >
                                            NIE
                                        </div>
                                        <div
                                            onClick={(e) => {
                                                setFormData({ ...formData, cashRegister: true })
                                                setErrorMessages({ ...errorMessages, cashRegister: '' })
                                            }}
                                        >
                                            TAK
                                        </div>
                                    </div>
                                    {errorMessages.cashRegister && <small className="input-wrapper__error-message">{errorMessages.cashRegister}</small>}
                                </div>
                            </div>

                            <div className="form-table__row-wrapper">
                                <p className="input-wrapper__label" style={{ fontSize: '16px', fontWeight: '600', textAlign: 'center' }}>Średniomiesięczna ilość transakcji z kontrahentami zagranicznymi <span style={{ color: '#ffd2d2' }}>(lub przewidywana ilość)</span> w tym:</p>

                                <div className="form-table__row">
                                    <div className="form-table__cell form-table__cell--label">
                                        <p className="input-wrapper__label">transakcje związane ze świadczeniem usług</p>
                                    </div>
                                    <div className="form-table__cell form-table__cell--input">
                                        <input
                                            type="monthlyTransactionQtyAbroadClientServices"
                                            name="monthlyTransactionQtyAbroadClientServices"
                                            id="monthlyTransactionQtyAbroadClientServices"
                                            placeholder="Oreintacyjna liczba"
                                            className="input-wrapper__input"
                                            value={formData.monthlyTransactionQtyAbroadClientServices} onChange={(e) => {
                                                setFormData({ ...formData, monthlyTransactionQtyAbroadClientServices: e.target.value })
                                                setErrorMessages({ ...errorMessages, monthlyTransactionQtyAbroadClientServices: '' })
                                            }}
                                        />
                                        {errorMessages.monthlyTransactionQtyAbroadClientServices && <small className="input-wrapper__error-message">{errorMessages.monthlyTransactionQtyAbroadClientServices}</small>}
                                    </div>
                                </div>

                                <div className="form-table__row">
                                    <div className="form-table__cell form-table__cell--label">
                                        <p className="input-wrapper__label">transakcje towarowe <span style={{ color: '#ffd2d2' }}>(eksport, import, WNT, WDT, sprzedaż towarów przez internet)</span></p>
                                    </div>
                                    <div className="form-table__cell form-table__cell--input">
                                        <input
                                            type="monthlyTransactionQtyAbroadClientFreight"
                                            name="monthlyTransactionQtyAbroadClientFreight"
                                            id="monthlyTransactionQtyAbroadClientFreight"
                                            placeholder="Oreintacyjna liczba"
                                            className="input-wrapper__input"
                                            value={formData.monthlyTransactionQtyAbroadClientFreight} onChange={(e) => {
                                                setFormData({ ...formData, monthlyTransactionQtyAbroadClientFreight: e.target.value })
                                                setErrorMessages({ ...errorMessages, monthlyTransactionQtyAbroadClientFreight: '' })
                                            }}
                                        />
                                        {errorMessages.monthlyTransactionQtyAbroadClientFreight && <small className="input-wrapper__error-message">{errorMessages.monthlyTransactionQtyAbroadClientFreight}</small>}
                                    </div>
                                </div>

                                <div className="form-table__row">
                                    <div className="form-table__cell form-table__cell--label">
                                        <p className="input-wrapper__label">
                                            Sprzedaż towarów przez internet tzw. konsumentom posiadającym miejsce zamieszkania w innym niż Polska kraju należącym do UE
                                        </p>
                                    </div>
                                    <div className="form-table__cell form-table__cell--input">
                                        <div
                                            className={`input-wrapper__switch ${formData.internetSalesToAbroadClient ? 'active' : ''}`}
                                        >
                                            <div
                                                onClick={(e) => {
                                                    setFormData({ ...formData, internetSalesToAbroadClient: false })
                                                    setErrorMessages({ ...errorMessages, internetSalesToAbroadClient: '' })
                                                }}
                                            >
                                                NIE
                                            </div>
                                            <div
                                                onClick={(e) => {
                                                    setFormData({ ...formData, internetSalesToAbroadClient: true })
                                                    setErrorMessages({ ...errorMessages, internetSalesToAbroadClient: '' })
                                                }}
                                            >
                                                TAK
                                            </div>
                                        </div>
                                        {errorMessages.internetSalesToAbroadClient && <small className="input-wrapper__error-message">{errorMessages.internetSalesToAbroadClient}</small>}
                                    </div>
                                </div>
                            </div>
                            {isSpecial && (
                                <div className="form-table__row-special-wrapper">
                                    <p className="input-wrapper__label" style={{ fontSize: '16px', fontWeight: '600', textAlign: 'center' }}>Wypełniają tylko osoby prawne prowadzące pełne księgi rachunkowe:</p>

                                    <div className="form-table__row">
                                        <div className="form-table__cell form-table__cell--label">
                                            <p className="input-wrapper__label">Ilość rachunków bankowych <span style={{ color: '#ffd2d2' }}>(lub przewidywana ilość)</span></p>
                                        </div>
                                        <div className="form-table__cell form-table__cell--input">
                                            <input
                                                type="specialQtyBankAccounts"
                                                name="specialQtyBankAccounts"
                                                id="specialQtyBankAccounts"
                                                placeholder="Oreintacyjna liczba"
                                                className="input-wrapper__input"
                                                value={formData.specialQtyBankAccounts} onChange={(e) => {
                                                    setFormData({ ...formData, specialQtyBankAccounts: e.target.value })
                                                    setErrorMessages({ ...errorMessages, specialQtyBankAccounts: '' })
                                                }}
                                            />
                                            {errorMessages.specialQtyBankAccounts && <small className="input-wrapper__error-message">{errorMessages.specialQtyBankAccounts}</small>}
                                        </div>
                                    </div>

                                    <div className="form-table__row">
                                        <div className="form-table__cell form-table__cell--label">
                                            <p className="input-wrapper__label">W tym walutowych</p>
                                        </div>
                                        <div className="form-table__cell form-table__cell--input">
                                            <input
                                                type="specialQtyInternationalBankAccount"
                                                name="specialQtyInternationalBankAccount"
                                                id="specialQtyInternationalBankAccount"
                                                placeholder="Oreintacyjna liczba"
                                                className="input-wrapper__input"
                                                value={formData.specialQtyInternationalBankAccount} onChange={(e) => {
                                                    setFormData({ ...formData, specialQtyInternationalBankAccount: e.target.value })
                                                    setErrorMessages({ ...errorMessages, specialQtyInternationalBankAccount: '' })
                                                }}
                                            />
                                            {errorMessages.specialQtyInternationalBankAccount && <small className="input-wrapper__error-message">{errorMessages.specialQtyInternationalBankAccount}</small>}
                                        </div>
                                    </div>

                                    <div className="form-table__row">
                                        <div className="form-table__cell form-table__cell--label">
                                            <p className="input-wrapper__label">Kasa gotówkowa</p>
                                        </div>
                                        <div className="form-table__cell form-table__cell--input">
                                            <div className={`input-wrapper__switch ${formData.specialCashRegister ? 'active' : ''}`}>
                                                <div
                                                    onClick={(e) => {
                                                        setFormData({ ...formData, specialCashRegister: false })
                                                        setErrorMessages({ ...errorMessages, specialCashRegister: '' })
                                                    }}
                                                >
                                                    NIE
                                                </div>
                                                <div
                                                    onClick={(e) => {
                                                        setFormData({ ...formData, specialCashRegister: true })
                                                        setErrorMessages({ ...errorMessages, specialCashRegister: '' })
                                                    }}
                                                >
                                                    TAK
                                                </div>
                                            </div>
                                            {errorMessages.specialCashRegister && <small className="input-wrapper__error-message">{errorMessages.specialCashRegister}</small>}
                                        </div>
                                    </div>

                                    <div className="form-table__row">
                                        <div className="form-table__cell form-table__cell--label">
                                            <p className="input-wrapper__label">Jeśli kasa gotówkowa jest walutowa, to ile walut?</p>
                                        </div>
                                        <div className="form-table__cell form-table__cell--input">
                                            <input
                                                type="specialInternationalCashRegister"
                                                name="specialInternationalCashRegister"
                                                id="specialInternationalCashRegister"
                                                placeholder="Oreintacyjna liczba"
                                                className="input-wrapper__input"
                                                value={formData.specialInternationalCashRegister} onChange={(e) => {
                                                    setFormData({ ...formData, specialInternationalCashRegister: e.target.value })
                                                    setErrorMessages({ ...errorMessages, specialInternationalCashRegister: '' })
                                                }}
                                            />
                                            {errorMessages.specialInternationalCashRegister && <small className="input-wrapper__error-message">{errorMessages.specialInternationalCashRegister}</small>}
                                        </div>
                                    </div>

                                    <div className="form-table__row">
                                        <div className="form-table__cell form-table__cell--label">
                                            <p className="input-wrapper__label">Przyjmowanie płatności kartą / blikiem</p>
                                        </div>
                                        <div className="form-table__cell form-table__cell--input">
                                            <div className={`input-wrapper__switch ${formData.specialCardOrBlikPayment ? 'active' : ''}`} >
                                                <div
                                                    onClick={(e) => {
                                                        setFormData({ ...formData, specialCardOrBlikPayment: false })
                                                        setErrorMessages({ ...errorMessages, specialCardOrBlikPayment: '' })
                                                    }}
                                                >
                                                    NIE
                                                </div>
                                                <div
                                                    onClick={(e) => {
                                                        setFormData({ ...formData, specialCardOrBlikPayment: true })
                                                        setErrorMessages({ ...errorMessages, specialCashRegister: '' })
                                                    }}
                                                >
                                                    TAK
                                                </div>
                                            </div>
                                            {errorMessages.specialCardOrBlikPayment && <small className="input-wrapper__error-message">{errorMessages.specialCardOrBlikPayment}</small>}
                                        </div>
                                    </div>
                                </div>
                            )}


                            <div className="form-table__row">
                                <div className="form-table__cell form-table__cell--label">
                                    <p className="input-wrapper__label">Ilość pracowników zatrudnionych na umowy o pracę* <span style={{ color: '#ffd2d2' }}>(lub przewidywana ilość)</span></p>
                                </div>
                                <div className="form-table__cell form-table__cell--input">
                                    <input
                                        type="uopEmployees"
                                        name="uopEmployees"
                                        id="uopEmployees"
                                        placeholder="Oreintacyjna liczba"
                                        className="input-wrapper__input"
                                        value={formData.uopEmployees} onChange={(e) => {
                                            setFormData({ ...formData, uopEmployees: e.target.value })
                                            setErrorMessages({ ...errorMessages, uopEmployees: '' })
                                        }}
                                    />
                                    {errorMessages.uopEmployees && <small className="input-wrapper__error-message">{errorMessages.uopEmployees}</small>}
                                </div>
                            </div>

                            <div className="form-table__row">
                                <div className="form-table__cell form-table__cell--label">
                                    <p className="input-wrapper__label">
                                        Ilość zleceniobiorców, umów o dzieło, wypłaty wynagrodzeń na podstawie uchwały powołania* <span style={{ color: '#ffd2d2' }}>(lub przewidywana ilość)</span>
                                    </p>
                                </div>
                                <div className="form-table__cell form-table__cell--input">
                                    <input
                                        type="quantityMandatory"
                                        name="quantityMandatory"
                                        id="quantityMandatory"
                                        placeholder="Oreintacyjna liczba"
                                        className="input-wrapper__input"
                                        value={formData.quantityMandatory} onChange={(e) => {
                                            setFormData({ ...formData, quantityMandatory: e.target.value })
                                            setErrorMessages({ ...errorMessages, quantityMandatory: '' })
                                        }}
                                    />
                                    {errorMessages.quantityMandatory && <small className="input-wrapper__error-message">{errorMessages.quantityMandatory}</small>}
                                </div>
                            </div>

                            <div className="form-table__row">
                                <div className="form-table__cell form-table__cell--label">
                                    <p className="input-wrapper__label">Inne informacje</p>
                                </div>
                                <div className="form-table__cell form-table__cell--input">
                                    <textarea
                                        type="otherInformation"
                                        name="otherInformation"
                                        id="otherInformation"
                                        rows={4}
                                        placeholder="Napisz..."
                                        className="input-wrapper__input"
                                        value={formData.otherInformation} onChange={(e) => {
                                            setFormData({ ...formData, otherInformation: e.target.value })
                                            setErrorMessages({ ...errorMessages, otherInformation: '' })
                                        }}
                                    />
                                    {errorMessages.otherInformation && <small className="input-wrapper__error-message">{errorMessages.otherInformation}</small>}
                                </div>
                            </div>

                            <div className="form-table__row">
                                <div className="form-table__cell form-table__cell--label">
                                    <div
                                        className="input-wrapper__checkbox"
                                        onClick={(e) => {
                                            setFormData({ ...formData, agreement1: !formData?.agreement1 })
                                            setErrorMessages({ ...errorMessages, agreement1: '' })
                                        }}
                                    >
                                        <div className={`input-wrapper__checkbox__box ${formData?.agreement1 ? 'active' : ''}`}></div>
                                        <p className="input-wrapper__checkbox__text">
                                            Wyrażam zgodę na przetwarzanie moich danych osobowych przez eRachunkowa Sp. z o.o. z siedzibą w Warszawie, w celu realizacji ankiety
                                            oraz przygotowania oferty, zgodnie z przepisami Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. (RODO).
                                            {errorMessages.agreement1 && <small className="special-input-container__error-message">{errorMessages.agreement1}</small>}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="form-table__row">
                                <div className="form-table__cell form-table__cell--label">
                                    <div
                                        className="input-wrapper__checkbox"
                                        onClick={(e) => {
                                            setFormData({ ...formData, agreement2: !formData?.agreement2 })
                                            setErrorMessages({ ...errorMessages, agreement2: '' })
                                        }}
                                    >
                                        <div className={`input-wrapper__checkbox__box ${formData?.agreement2 ? 'active' : ''}`} />
                                        <p className="input-wrapper__checkbox__text">
                                            Wyrażam zgodę na kontakt ze mną przez eRachunkowa Sp. z o.o. z siedzibą w Warszawie za pośrednictwem e-maila oraz telefonu w celu
                                            przygotowania korzystnej oferty na podstawie przesłanej ankiety.
                                            {errorMessages.agreement2 && <small className="special-input-container__error-message">{errorMessages.agreement2}</small>}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <input disabled={areAnyErrors} className={`input-wrapper__submit ${areAnyErrors ? 'disabled' : ''} ${alreadySubmitted ? 'submitted' : ''}`} type="submit" value={alreadySubmitted ? 'WYSŁANO ANKIETĘ' : areAnyErrors ? 'WYSTĄPIŁ BŁĄD ANKIETY' : 'WYŚLIJ ANKIETĘ'} />
                        {areAnyErrors && <p className="input-wrapper__submit-error">{errorMessage}</p>}
                    </form>
                </div>
            </div>
        </section>
    )
}

export default ContactSection;
